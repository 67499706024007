import { useState, useEffect } from 'react';
import { TrashIcon,EyeIcon,LockClosedIcon } from "@heroicons/react/20/solid";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PreselectionService from '../services/preselection.service';
import GradeService from '../services/grades.services';
import RealMockApi from '../api/realMockApi';
import moment from 'moment/moment';
import SweetAlertModal from '../components/SweetAlert';
import {useQuery } from '@tanstack/react-query';
import SingleLoading from '../components/SingleLoading';


const PreselectionView = () =>{
   const MySwal = withReactContent(Swal);
   const [sessionArray, setSessionArray] = useState([]);
   const [newSessionArray, setNewSessionArray] = useState([]);
   const realMockApi = new RealMockApi();
   const [enrollment,setEnrollment] = useState("");
   const [periods,setPeriod] = useState([]);
   const [name,setName] = useState("");
   const [currentUserId,setUserId] = useState("");
   const [selectedYear, setSelected] = useState();
   const [currentYear, setCurrentYear] = useState();
   const [selectedPeriod,setSelectedPeriod] = useState();
   const sweetAlertModal = new SweetAlertModal();
   const [scheduleSelected,setScheduleSelected] = useState([]);

   const [loading, setLoading] = useState(false);
   const [loadingSave, setLoadingSave] = useState(false);

   const [loadingDelete, setLoadingDelete] = useState(false);

   const [firstSelection, setFirstSelection] =useState([]);
   const [updateAvailable, setUpdateAvailable] = useState(false);

   const preselectionService = new PreselectionService();
   const gradeService = new GradeService();

   const [userPreselection, setUserPreselection] = useState([]);
   const [userNewSelection, setuserNewSelection] = useState([]);
   const [pensumType,setPensumType] = useState("");
   const [availableCredit, setAvailableCredit] = useState(24);
   const [open, setOpen] = useState();

   const [asignaturasEditadas, setAsignaturasEditadas]=useState(0);

   const [updatePreselection, setUpdatePreselection] = useState([]);
   const [selectionActive, setSelectionActive] = useState(false);
   // const [Session, setSession] = useState([]);

   const {isLoading, data} = useQuery(
      [ 'sessions', open, selectedPeriod, selectedYear ],
      () => preselectionService.getActiveSessions(open, selectedPeriod, selectedYear),
      {
         enabled:!!open,
      }
   )
   
   useEffect(()=>{
      setEnrollment(localStorage.getItem("userIdLoggin"));
      setName(localStorage.getItem("userName"));
      setUserId(localStorage.getItem("userID"));
      setCurrentYear(new Date().getFullYear());
      setuserNewSelection([]);
      setUserPreselection([]);
      setUserPreselection([]);
      setFirstSelection([]);
      setSessionArray([]);
      setAvailableCredit(0);
      setUpdatePreselection([]);
      setNewSessionArray([]);
      
      const getPreselectionDate = async () =>{
         setLoading(true);
         preselectionService.validatePreselectionDate().then((value)=>{
            const today = new Date();
            const rangeDates=value?.data?.data;
            const fechaInicio = new Date(rangeDates[1]?.fechaInicio);
            const fechaFin = new Date(rangeDates[1]?.fechaFin);
            if (today >= fechaInicio && today <= fechaFin) {
               setSelectionActive(true);
            }
            setLoading(false);
         });
       }   
       getPreselectionDate();
   },[]);

   const cleanVariables=()=>{
      setuserNewSelection([]);
      setUserPreselection([]);
      setUserPreselection([]);
      setFirstSelection([]);
      setSessionArray([]);
      setAvailableCredit(0);
      setUpdatePreselection([]);
      setNewSessionArray([]);
      setScheduleSelected([]);
   }

   const findPeriods = event => {
      setSelected(event.target.value);
      if(event.target.value === "#"){
         setPeriod([]);
      }
      gradeService.getPeriodsByYear(event.target.value).then((value) => {
         setPeriod(value.data.data);
         const data = value.data.data;
         setPeriod(data);
      });
   };

   const handleOpen = (value) => {
      setOpen(open === value ? '' : value);
   };
 

   const findSubjects = async () => {
      setFirstSelection([]);
      setLoadingDelete(true);
      setOpen(false);
      let totalCredito=0;
      let creditosSelected=0;
      let reglaTipo;
      let dataGeneral=[];
      let Preselection=[];
      const firstSelectionAll=[];
      const sessionAllArray =[];
      setScheduleSelected([]);
      cleanVariables();
      await preselectionService.getPreselectionSelected(currentUserId, selectedPeriod, selectedYear).then( async (value) =>{
         dataGeneral =value.data.data;
         if(dataGeneral.length){
            for (const [key,value] of Object.entries(dataGeneral)){
               await preselectionService.getActiveSessions(value.asignaturaId, selectedPeriod, selectedYear).then((sessions) =>{
                  const allSessions=sessions.data.data;
                  for (const obj of dataGeneral) {
                     for (const sesion of allSessions){
                        if(sesion.groupId === obj.groupId){
                           sessionAllArray.push({subject: obj.asignaturaId, group: obj.groupId, checkStatus: true});
                           addScheduleList(true,obj.schedule,obj.asignaturaId,obj.startDate,obj.endDate);
                           firstSelectionAll.push({subject: obj.asignaturaId, group: obj.groupId, checkStatus: !validateSession(obj.groupId)});
                           creditosSelected=creditosSelected+obj.credito;
                        }
                     }
                     if (obj.asignaturaId === value.asignaturaId) {
                        obj.sessions = allSessions;   
                        break;
                     }
                  }
               })
            }
         }
         console.log(scheduleSelected);
      })

      await preselectionService.getPreselection(currentUserId, selectedPeriod, selectedYear).then(async (value) =>{
         Preselection = value.data.data;
         setSessionArray(sessionAllArray);
      })

      await preselectionService.getReglaPensum(currentUserId).then( (values) =>{
         const reglaPensum = values.data.data;
         reglaTipo=reglaPensum?.tipoRegla;
         totalCredito=reglaPensum?.maximoValido;
         setAvailableCredit(reglaPensum?.maximoValido);
         for (const sesion of dataGeneral){
            const index = Preselection.findIndex(object => object.subjectCode === sesion.codigoAsinatura);
            Preselection.splice(index, 1);
         }
         setFirstSelection(firstSelectionAll);
         setuserNewSelection(Preselection);
         setPensumType(reglaTipo);
         setUserPreselection(dataGeneral);
         setAsignaturasEditadas(firstSelectionAll?.length);
         if(pensumType===1){
            setAvailableCredit(totalCredito-creditosSelected);
         }
         else{
            setAvailableCredit(totalCredito-firstSelectionAll?.length);
         }
         setLoadingDelete(false);
   });
};
   
   const handleSelectedPeriod = (value) => {
      setSelectedPeriod(event.target.value);
   };

  const deleteSignature = async (preselectionId, signature,key,credit) => {
   const datos = [];
   datos.push(preselectionId);
   setLoadingDelete(true);
   sweetAlertModal.deletePreselectionConfirm({mensaje:'¿Deseas eliminar ' + signature +'?', title:'!Guardando cambios!', data: datos}).then((value)=>{
      if(value===true){
         findSubjects();
      }else{  
         setLoadingDelete(false);
      }
   });
  };

   function convertToStandardTime(input) {
      if(input === "" || input === null || input === undefined){
         return ""
      }
      if(parseInt(input) < 1000){
         if(input.length === 3){
            input = input.substring(0, 1)+":"+input.substring(1)+"AM"
            return input;
         }else{
            input = input.substring(0, 2)+":"+input.substring(2)+"AM"
            return input;
         }

      }
      return moment(input, 'HH:mm:ss').format('h:mm:ss A');
  }

      const getElementArrayPosition = (arrElement) => {
         const position = sessionArray.findIndex(index =>
            index.subject === arrElement);
         return position;
      }

      const updateArrayValue = (subjectId, groupId)  =>{
         const startPosition = getElementArrayPosition(subjectId)
         sessionArray.splice(startPosition, 1)
      }

      const newUpdateArrayValue = (subjectId, groupId)  =>{
         const startPosition = getElementArrayPosition(subjectId)
         newSessionArray.splice(startPosition, 1)
      }

      function addScheduleList(isChecked, schedule, subjectId, fromDate, toDate) {
         setScheduleSelected(prevScheduleSelected => {
           const newScheduleSelected = prevScheduleSelected.slice(); // Hacer una copia del arreglo original
           for (const [key, value] of Object.entries(schedule)) {
             if (isChecked) {
               newScheduleSelected[subjectId] = {
                 subjectId,
                 'day': value.day,
                 'startTime': value.startTime,
                 'endTime': value.endTime,
                 'startDate': fromDate,
                 'endDate': toDate
               };
             } else {
               delete scheduleSelected[subjectId];
               delete newScheduleSelected[subjectId];
             }
           }
           return newScheduleSelected;
         });
       }
       


      function formatDate(date){
         const e1start = moment(date, "MM/DD/YYYY").format("YYYY/MM/DD");
         const correctDate=new Date(e1start + 'GMT');
         const completeDate=correctDate.getTime();
         return completeDate;
      }

      function formatingDate(date){
         const formatDate = moment(date, "MM/DD/YYYY").format("DD/MM/YYYY");
         return formatDate;
      }

      function validateSchedule(isChecked,schedule,fromDate,toDate){
         for (const [key,value] of Object.entries(schedule)){
            if(isChecked){
               for (const [keys,values] of Object.entries(scheduleSelected)){
                  const StartA = formatDate(fromDate);
                  const EndA = formatDate(toDate);
                  const StartB = formatDate(values.startDate);
                  const EndB = formatDate(values.endDate);
                  if((StartA <= EndB) && (EndA >= StartB)){
                     if(value.day === values.day){
                        const startTimeA=values.startTime;
                        const endTimeA=values.endTime-1;
                        const startTimeB=value.startTime;
                        const endTimeB=value.endTime-1;
                        console.log(startTimeA,endTimeB,endTimeA,startTimeB);
                        if((startTimeA <= endTimeB) && (endTimeA >= startTimeB)){
                           return false;
                        }
                     }
                  }
               }
            }
         }
         return true;
      }
      
      function addGroupsId(subjectId, groupId,credit, isChecked,schedule,quota,fromDate,toDate,preSeleccionId,isNew) {
         const firstSelectionIndex = firstSelection.findIndex(object => object.group === groupId)
         const index = updatePreselection.findIndex(object => object.idPrematricula === preSeleccionId);
         let ac = 0;
         const position = sessionArray.findIndex(index =>
            index.subject === subjectId && index.group === groupId);
            if(!isChecked){
                  delete scheduleSelected[subjectId];
                  document.getElementById(groupId).checked=false;
                  if(!isNew){
                     updatePreselection.splice(index, 1);
                     setAsignaturasEditadas(asignaturasEditadas-1);
                  }
                  if (position >= 0){
                     if(pensumType===1){
                        ac = availableCredit + credit;
                     }
                     else{
                        ac = availableCredit + 1;
                     }
                     setAvailableCredit(ac);
                     updateArrayValue(subjectId, groupId);
                     newUpdateArrayValue(subjectId, groupId);
                  }
            }else{
               const position1 = sessionArray.findIndex(index =>
                  index.subject === subjectId);
                  if (position1 >= 0){
                     Swal.fire(   'Información',   'Ya tiene una sesión seleccionada para esta materia.',   'error' )
                     document.getElementById(groupId).checked=false;
                  }
                  else if(!validateSchedule(isChecked,schedule,fromDate,toDate)){
                     Swal.fire(   'Información',   'Conflicto con selección previa, por favor validar.',   'error' )
                     document.getElementById(groupId).checked=false;
                  }
                  else if(quota<1){
                     Swal.fire({
                        title:'Sesión sin cupos disponibles', 
                        icon:'error',
                        confirmButtonColor: '#16a34a',
                        confirmButtonText: 'Cerrar'
                     })
                     document.getElementById(groupId).checked=false;
                  }
                  else{
                        let message;
                        if(pensumType===1){
                           ac = availableCredit - credit;
                           message='Excediste la cantidad máxima de créditos por selección';
                        }
                        else{
                           ac = availableCredit - 1;
                           message='Excediste la cantidad máxima de materias por selección';
                        }
                     
                        
                        
                        if(ac < 0){
                           Swal.fire(   'Información',   message,   'error' )
                           document.getElementById(groupId).checked=false;
                        }
                        else{
                           if(!isNew){
                              if (index === -1 && firstSelectionIndex === -1) {
                                 updatePreselection.push({idPrematricula: preSeleccionId,idGrupo:groupId});
                              
                                 setAsignaturasEditadas(asignaturasEditadas+1);
                              }
                           }
                           else{
                              newSessionArray.push({subject: subjectId, group: groupId, checkStatus: true});
                           }
                           addScheduleList(isChecked,schedule,subjectId,fromDate,toDate);
                           sessionArray.push({subject: subjectId, group: groupId, checkStatus: true});
                           document.getElementById(groupId).checked=true;
                           setAvailableCredit(ac);
                        }

                     }
            }
            setUpdateAvailable(true);
      }


      async function handleSave(e){   
         if(asignaturasEditadas < firstSelection.length){
            Swal.fire({
               title:'No puede dejar una materia sin seleccionar. Selecciona o elimina la materia en cuestión de la prematricula', 
               icon:'error',
               confirmButtonColor: '#16a34a',
               confirmButtonText: 'Cerrar'
            })
         }
         else if(asignaturasEditadas >= firstSelection.length || newSessionArray.length){
            const groupIds = [];
            newSessionArray.forEach(function(obj){
               groupIds.push(obj.group);
            })
   
            const updatePreselectionJson={
               "personId":currentUserId,
               updatePreselection
            };
            if(sessionArray.length <=0){
               Swal.fire({
                  title:'Debes seleccionar al menos una sesión.', 
                  icon:'error',
                  confirmButtonColor: '#16a34a',
                  confirmButtonText: 'Cerrar'
               })
            }
            else if(!updatePreselection.length && !groupIds.length){
               Swal.fire({
                  title:'No existen cambios a guardar.', 
                  icon:'error',
                  confirmButtonColor: '#16a34a',
                  confirmButtonText: 'Cerrar'
               })
            }else{
               setLoadingSave(true);
               sweetAlertModal.executeConfirmUpdateSelection({mensaje:'¿Deseas continuar?', title:'!Guardando cambios!',currentUserId,updatePreselectionJson,groupIds}).then((value) =>{
                  if(value?.status===200){
                     cleanVariables();
                  }
                  setLoadingSave(false);
               });
            }
         }
         
      };

      function handleCancel(e){
         MySwal.fire({
            html: <b>¿Deseas cancelar la preselección?</b>,
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonColor: '#16a34a',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            }).then(async (result) => {
               if (result.isConfirmed) {
                  Swal.fire({
                     title:'¡Preselección cancelada!', 
                     icon:'success',
                     confirmButtonColor: '#16a34a',
                     confirmButtonText: 'Cerrar'
                  });
                  cleanVariables();
               }
            })
      };

      function validateSession(itemId){
         
         return sessionArray.find((items) =>{
            return items.group===itemId;
         })
      }
      

   return (
      <div className="pt-0 sm:pt-10">
      {
      loading ? (
         <div className="grid h-64 justify-items-center content-end">
         <SingleLoading className="px-40 py-6"/>
         </div>                                                      
      ): (
      <div className="pt-0 sm:pt-10">
         {
            !selectionActive? (
               <div className="grid h-64 justify-items-center content-end">
                  <h1 className="text-3xl font-semibold text-red-600 ">Proceso de selección cerrado</h1>
                  <div className="px-40 py-6">
                     <LockClosedIcon className="h-14 text-red-600 font-extralight text-md" />
                  </div>
               </div>
            ):(
         <div className="p-12">
            {/* {groupIds} */}
            <h1 className="mb-0 text-xl font-semibold text-green-600">Preselección</h1>
            <div className="grid grid-cols-1 gap-4 pt-6 mb-12 bg-white lg:grid-cols-4">
               <div className="">
                  <label  className="block mb-2 ml-5 text-sm font-medium text-gray-900">Matrícula</label>
                  <input id="matricula" type="text" placeholder="Matrícula" className="w-full px-4 py-2 border-2 rounded-2xl bg-gray-50" value={enrollment} disabled />
               </div>
               <div className="">
                  <label  className="block mb-2 ml-5 text-sm font-medium text-gray-900">Nombre</label>
                  <input id="nombre" type="text" placeholder="Nombre" className="w-full px-4 py-2 border-2 rounded-2xl bg-gray-50" value={name} disabled />
               </div>
               <div className="">
                  <label className="block mb-2 ml-5 text-sm font-medium text-gray-900">Año</label>
                  <select value={selectedYear} onChange={findPeriods} id="year" className="w-full px-4 py-2 border-2 rounded-2xl bg-gray-50">
                     <option value="#">Seleccione</option>
                     <option value={parseInt(currentYear)}>{currentYear}</option>
                     <option value={parseInt(currentYear)+1}>{currentYear+1}</option>
                  </select>
               </div>
               <div className="">
                  <label className="block mb-2 ml-5 text-sm font-medium text-gray-900">Periodo</label>
                  <select id="periodo" value={selectedPeriod} onChange={handleSelectedPeriod} className="w-full px-4 py-2 border-2 rounded-2xl bg-gray-50">
                  <option value="#">Seleccione</option>
                     {
                        periods.map((value) => {
                           return <>
                           <option value={value.id}>{value.name}</option>
                           </>
                        })
                     }
                  </select>
               </div>
               <div className="">
                  <button onClick={findSubjects} disabled={loading || !selectedPeriod?.length} className="px-4 py-2 font-semibold text-white uppercase bg-green-600 rounded-full shadow-lg cursor-pointer hover:bg-green-700">
                Buscar
               </button>
               </div>

            </div>
            <span className="block mb-2 text-sm text-right">
               {
                  userPreselection.length || userNewSelection.length ? ( 
                  <>
                     {
                     pensumType===1 ? ('Total de creditos disponibles') : ('Total de materias disponibles')
                     }: <b>{availableCredit}</b>
                  </>
                  ) : (null)
               }
            </span>
            <div className="relative overflow-x-auto border sm:rounded-lg">
               <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-green-600">
                     <tr>
                     {
                           realMockApi.titleTable().map((value,index)=> (
                              <th key={index} scope="col" className="px-6 py-3 border-r">
                                 {value}
                              </th>
                           ))
                        }
                     </tr>
                  </thead>
                  {
                     !loading && !loadingDelete && !loadingSave ? (
                        <>
                  {
                     userNewSelection.length ? (
                     
                        <tbody>
                        <h1 className="my-5 mx-5 text-xl font-semibold text-green-600">Agregar asignatura</h1>
                        {
                              userNewSelection.map((value) => {
                              return <>
                                 <tr key={value.id} className="font-bold bg-white border-t border-gray-200">
                                       <td className="px-6 py-2">{value.subjectCode}</td>
                                       <td className="px-6 py-2">{value.subjectName}</td>
      
                                       <td className="px-6 py-2">{value.credit}</td>
                                       <td className="px-6 py-2">{
                                       value.requirements.map((value)=>{
                                          return <>{value}<br/></>
                                       })}</td>
                                       
                                       <td className="px-6 py-2">
                                          <EyeIcon onClick={() => handleOpen(value.subjectId)} className="h-6 text-green-600 font-extralight text-md" />
                                       </td>
                                    </tr>
                                    {
                                       open === value.subjectId &&
                                       <tr className="px-5 bg-white border-t border-gray-200">
                                          <td className="" colSpan="6">
                                             <div className="px-10 pt-2 pb-10 bg-green-100">
                                                <table border="5px"  className="w-full mb-2 shadow-lg">
                                                   <thead className="text-xs text-white bg-gray-500">
                                                      <tr>
                                                         <th scope="col" className="px-6 py-2 border-r">
                                                            Seleccionada
                                                         </th>
                                                         <th scope="col" className="px-6 py-2 border-r">
                                                            Sesión
                                                         </th>
                                                         <th scope="col" className="px-6 py-2 border-r">
                                                            Cupos
                                                         </th>
                                                         {/* <th scope="col" className="px-6 py-2 border-r">
                                                            Recinto
                                                         </th> */}
                                                         <th scope="col" className="px-6 py-2 border-r">
                                                            Fecha inicio
                                                         </th>
                                                         <th scope="col" className="px-6 py-2 border-r">
                                                            Fecha fin
                                                         </th>
                                                         <th scope="col" className="px-6 py-2 border-r">
                                                            Aula
                                                         </th>
                                                         
                                                         <th scope="col" className="px-6 py-2 border-r">
                                                            Lun
                                                         </th>
                                                         <th scope="col" className="px-6 py-2 border-r">
                                                            Mar
                                                         </th>
                                                         <th scope="col" className="px-6 py-2 border-r">
                                                            Mie
                                                         </th>
                                                         <th scope="col" className="px-6 py-2 border-r">
                                                            Jue
                                                         </th>
                                                         <th scope="col" className="px-6 py-2 border-r">
                                                            Vie
                                                         </th>
                                                         <th scope="col" className="px-6 py-2 border-r">
                                                            Sab
                                                         </th>
                                                         
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      
                                                      {
   
   
                                                         isLoading ? (
                                                            <SingleLoading/>                                                         
                                                         ):
                                                         
                                                         data.data.data.length <=0 ?  (
                  
                                                            <tr>
                                                               <td colSpan={5} className='py-5 text-center font-bold'>
                                                                  No hay sesiones habilitadas para esta materia
                                                               </td>
                                                            </tr>
   
                                                         ):  (
                                                         
                                                         data.data.data.map((item) => {
                                                            const {groupId, groupNumber, startDate, endDate, quota, enclousere, schedule} = item
                                                            const section = {
                                                               groupId,
                                                               groupNumber,
                                                               startDate,
                                                               endDate,
                                                               quota,
                                                               enclousere,
                                                               classroom: schedule[0]?.classroom,
                                                               schedule: schedule.reduce((newschedule, s, i) => {
                                                                  return {
                                                                     ...newschedule, [s.day]: s
                                                                  }
                                                               }, {})
                                                               
                                                            }
                                                            
                                                            return <tr key={item.groupId} className="bg-white border-t border-gray-200 ">
                                                            <td className="px-3 py-1">
                                                            <input onClick={() => addGroupsId(value.subjectId, item.groupId, value.credit, !validateSession(item.groupId), section.schedule, item.quota, item.startDate,item.endDate,null,true)} type="checkbox"  defaultChecked={validateSession(item.groupId)} id={item.groupId} name={value.subjectCode} className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded cursor-pointer"></input>
                                                            </td>
                                                            <td className="px-3 py-1">
                                                               {item.groupNumber}
                                                            </td>
                                                            <td className="px-3 py-1">
                                                               {item.quota}
                                                            </td>
                                                            
                                                            {/* <td className="px-3 py-1">
                                                            {item.enclousere}
                                                            </td> */}
   
                                                            <td className="px-3 py-1">
                                                            {formatingDate(item.startDate.substring(0,10))}
                                                            </td>
   
                                                            <td className="px-3 py-1">
                                                            {formatingDate(item.endDate.substring(0,10))}
                                                            </td>
                                                            
                                                            <td className="px-3 py-1">
                                                            {section.classroom}
                                                            </td>
                                                            <td className="px-3 py-1">
                                                               {convertToStandardTime(section.schedule.Lunes?.startTime)} - {convertToStandardTime(section.schedule.Lunes?.endTime)}
                                                            </td>
                                                            <td className="px-3 py-1">
                                                            {convertToStandardTime(section.schedule.Martes?.startTime)} - {convertToStandardTime(section.schedule.Martes?.endTime)}
                                                            </td>
                                                            <td className="px-3 py-1">
                                                            {convertToStandardTime(section.schedule.Miércoles?.startTime)} - {convertToStandardTime(section.schedule.Miércoles?.endTime)}
                                                            </td>
                                                            <td className="px-3 py-1">
                                                            {convertToStandardTime(section.schedule.Jueves?.startTime)} - {convertToStandardTime(section.schedule.Jueves?.endTime)}
                                                            </td>
                                                            <td className="px-3 py-1">
                                                            {convertToStandardTime(section.schedule.Viernes?.startTime)} - {convertToStandardTime(section.schedule.Viernes?.endTime)}
                                                            </td>
                                                            <td className="px-3 py-1">
                                                            {convertToStandardTime(section.schedule.Sábado?.startTime)} - {convertToStandardTime(section.schedule.Sábado?.endTime)}
                                                            </td>
                                                         </tr>
                                                         })
                                                      
                                                         )
                                                      
                                                      }
                                                      
                                                      
                                                      
                                                   </tbody>
                                                </table>
                                             </div>
                                          </td>
                                       </tr>
                                    }
                              </>
                                                      
                               
                           })
                        
                           
                        }        
                     </tbody>
                     ) : (
                        null
                     )
                  }
                  </>
                  ):(null)}
                  
                  <tbody>
                     {
                        userPreselection.length && !loading && !loadingDelete && !loadingSave ? (
                           <>
                           <hr/>
                           <h1 className="my-5 mx-5 text-xl font-semibold text-green-600">Editar preseleccion</h1>
                           </>
                        ):(null)
                     }
                     {
                        !userPreselection.length || loading || loadingSave || loadingDelete ?  (
                           
                           <tr>
                              <td colSpan={5} className='py-5 text-center font-bold'>
                                 {
                                    loading || loadingDelete || loadingSave ? (
                                       <svg
                                       className='inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300'
                                       viewBox='0 0 100 101'
                                       fill='none'
                                       xmlns='http://www.w3.org/2000/svg'
                                     >
                                       <path
                                         d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                                         fill='currentColor'
                                       />
                                       <path
                                         d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                                         fill='currentFill'
                                       />
                                     </svg>
                                    ):(
                                       <>
                                       No hay materias que mostrar
                                       </>
                                    )
                                 }
                              </td>
                           </tr>

                        ):  (

                           userPreselection.map((value,index) => {
                           return <>
                              <tr key={value.id} className="font-bold bg-white border-t border-gray-200" disabled={loadingDelete}>
                                    <td className="px-6 py-2">{value.codigoAsinatura}</td>
                                    <td className="px-6 py-2">{value.nombreAsignatura}</td>
   
                                    <td className="px-6 py-2">{value.credito}</td>
                                    <td className="px-6 py-2">{null}</td>
                                    
                                    <td className="px-6 py-2">
                                    {loadingDelete ? (
                <svg
                  className='inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300'
                  viewBox='0 0 100 101'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='currentColor'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='currentFill'
                  />
                </svg>
              ) : (
               <TrashIcon onClick={() => deleteSignature(value.preSeleccionId, value.nombreAsignatura,index,value.credito)} className="h-6 text-green-600 font-extralight text-md" />
              )}
                                       
                                    </td>
                                 </tr>
                                    <tr className="px-5 bg-white border-t border-gray-200">
                                       <td className="" colSpan="6">
                                          <div className="px-10 pt-2 pb-10 bg-green-100">
                                             <table border="5px"  className="w-full mb-2 shadow-lg">
                                                <thead className="text-xs text-white bg-gray-500">
                                                   <tr>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Seleccionada
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Sesión
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Cupos
                                                      </th>
                                                      {/* <th scope="col" className="px-6 py-2 border-r">
                                                         Recinto
                                                      </th> */}
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Fecha inicio
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Fecha fin
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Aula
                                                      </th>
                                                      
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Lun
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Mar
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Mie
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Jue
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Vie
                                                      </th>
                                                      <th scope="col" className="px-6 py-2 border-r">
                                                         Sab
                                                      </th>
                                                      
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                   
                                                   {
                                                      value.sessions <=0 ?  (
               
                                                         <tr>
                                                            <td colSpan={5} className='py-5 text-center font-bold'>
                                                               No hay sesiones habilitadas para esta materia
                                                            </td>
                                                         </tr>

                                                      ):  (
                                                      
                                                      value.sessions.map((item) => {
                                                         const {groupId, groupNumber, startDate, endDate, quota, enclousere, schedule} = item
                                                         const section = {
                                                            groupId,
                                                            groupNumber,
                                                            startDate,
                                                            endDate,
                                                            quota,
                                                            enclousere,
                                                            classroom: schedule[0]?.classroom,
                                                            schedule: schedule.reduce((newschedule, s, i) => {
                                                               return {
                                                                  ...newschedule, [s.day]: s
                                                               }
                                                            }, {})
                                                            
                                                         }
                                                         
                                                         return <tr key={item.groupId} className="bg-white border-t border-gray-200 ">
                                                         <td className="px-3 py-1">
                                                         <input onClick={() => addGroupsId(value.asignaturaId, item.groupId,value.credito, !validateSession(item.groupId), section.schedule, item.quota, item.startDate,item.endDate, value.preSeleccionId,false)} type="checkbox"  defaultChecked={validateSession(item.groupId)} id={item.groupId} name={value.subjectCode} className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded cursor-pointer"></input>
                                                         </td>
                                                         <td className="px-3 py-1">
                                                            {item.groupNumber}
                                                         </td>
                                                         <td className="px-3 py-1">
                                                            {item.quota}
                                                         </td>
                                                         
                                                         {/* <td className="px-3 py-1">
                                                         {item.enclousere}
                                                         </td> */}

                                                         <td className="px-3 py-1">
                                                         {formatingDate(item.startDate.substring(0,10))}
                                                         </td>

                                                         <td className="px-3 py-1">
                                                         {formatingDate(item.endDate.substring(0,10))}
                                                         </td>
                                                         
                                                         <td className="px-3 py-1">
                                                         {section.classroom}
                                                         </td>
                                                         <td className="px-3 py-1">
                                                            {convertToStandardTime(section.schedule.Lunes?.startTime)} - {convertToStandardTime(section.schedule.Lunes?.endTime)}
                                                         </td>
                                                         <td className="px-3 py-1">
                                                         {convertToStandardTime(section.schedule.Martes?.startTime)} - {convertToStandardTime(section.schedule.Martes?.endTime)}
                                                         </td>
                                                         <td className="px-3 py-1">
                                                         {convertToStandardTime(section.schedule.Miércoles?.startTime)} - {convertToStandardTime(section.schedule.Miércoles?.endTime)}
                                                         </td>
                                                         <td className="px-3 py-1">
                                                         {convertToStandardTime(section.schedule.Jueves?.startTime)} - {convertToStandardTime(section.schedule.Jueves?.endTime)}
                                                         </td>
                                                         <td className="px-3 py-1">
                                                         {convertToStandardTime(section.schedule.Viernes?.startTime)} - {convertToStandardTime(section.schedule.Viernes?.endTime)}
                                                         </td>
                                                         <td className="px-3 py-1">
                                                         {convertToStandardTime(section.schedule.Sábado?.startTime)} - {convertToStandardTime(section.schedule.Sábado?.endTime)}
                                                         </td>
                                                      </tr>
                                                      })
                                                   
                                                      )
                                                   
                                                   }
                                                   
                                                   
                                                   
                                                </tbody>
               
                                             </table>
                                          </div>
                                       </td>
                                    </tr>
                                 
                           </>
                                                   
                            
                        })
                     
                        )
                     }        
                  </tbody>
               </table>
            </div>
            
               {
                  userPreselection.length || userNewSelection.length ? (
                     <div className="flex justify-end mt-16 mb-6 space-x-6">
                     <button disabled={loadingSave || loadingDelete || !updateAvailable} onClick={() => handleCancel()} className="px-10 py-2 font-semibold text-white uppercase bg-red-600 rounded-full shadow-lg cursor-pointer hover:bg-red-700">Cancelar</button>
                     <button disabled={loadingSave || loadingDelete || !updateAvailable} onClick={() => handleSave()} type="button" value="Guardar" className="px-10 py-2 font-semibold text-white uppercase bg-green-600 rounded-full shadow-lg cursor-pointer hover:bg-green-700">
                     Guardar
                     </button>
                     </div>
                  ):(
                     null
                  )
               }
            
         </div>
            )
         }
      </div>
      )
      }
      </div>
   );
}

export default PreselectionView;
